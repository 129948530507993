.authPage {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
}

.auth {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.authLogo {
  position: absolute;
  top: 30px;
  left: 30px;
  max-width: 180px;
  height: auto;
}

.authFooter {
  position: absolute;
  bottom: 30px;
  color: var(--color-neutral-contrast-500);
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;

  @media only screen and (min-width: 992px) {
    left: 30px;
    transform: none;
    text-align: left;
  }
}

.authText {
  margin: var(--spacing-2) 0 var(--spacing-8) 0;
}

.contentSide {
  display: flex;
  justify-content: center;
  flex-basis: 50%;
}

.imageSide {
  display: none;
  position: relative;
  flex-basis: 50%;
  max-width: 100%;
  background-image: url("/login-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media only screen and (min-width: 992px) {
    display: block;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(55, 125, 255, 0.4);
  }
}
